import request from '@/utils/request1';

// 召回管理查询
export function callBackList(data) {
    return request({ url: '/call_back/QCallBackApi', method: 'post', data })
}

// 召回管理新增
export function callBackAdd(data) {
    return request({ url: '/call_back/ACallBackApi', method: 'post', data })
}

// 召回管理编辑
export function callBackEdit(data) {
    return request({ url: '/call_back/UCallBackApi', method: 'post', data })
}

// 召回管理删除
export function callBackDel(data) {
    return request({ url: '/call_back/DCallBackApi', method: 'post', data })
}

// 不合格品管理查询
export function unqualifiedList(data) {
    return request({ url: '/unqualified/QUnqualifiedApi', method: 'post', data })
}

// 不合格品管理新增
export function unqualifiedAdd(data) {
    return request({ url: '/unqualified/AUnqualifiedApi', method: 'post', data })
}

// 不合格品管理编辑
export function unqualifiedEdit(data) {
    return request({ url: '/unqualified/UUnqualifiedApi', method: 'post', data })
}

// 不合格品管理删除
export function unqualifiedDel(data) {
    return request({ url: '/unqualified/DUnqualifiedApi', method: 'post', data })
}
